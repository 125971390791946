import './src/styles/global.css';
require('prismjs/themes/prism-okaidia.css');
require('prismjs/plugins/line-numbers/prism-line-numbers.css');

export const onClientEntry = () => {
  // 모달을 렌더링할 DOM 요소를 생성합니다.
  const modalRoot = document.createElement('div');
  modalRoot.setAttribute('id', 'modal-root');
  document.body.appendChild(modalRoot);
};
